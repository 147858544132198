"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BridgeTransactionStatus = void 0;
var BridgeTransactionStatus;
/* unix timestamp in milliseconds */

exports.BridgeTransactionStatus = BridgeTransactionStatus;

(function (BridgeTransactionStatus) {
  BridgeTransactionStatus["Pending"] = "Pending";
  BridgeTransactionStatus["Successful"] = "Successful";
  BridgeTransactionStatus["Failed"] = "Failed";
})(BridgeTransactionStatus || (exports.BridgeTransactionStatus = BridgeTransactionStatus = {}));