"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createAssetPredicate = createAssetPredicate;
exports.createAssetModel = createAssetModel;
exports.isValidHexStr = isValidHexStr;

var _lodash = _interopRequireDefault(require("lodash.isequal"));

var _errors = require("../errors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// typescript helper function
function createAssetPredicate(pred) {
  if (typeof pred !== 'function') (0, _errors.boom)('param of `createAssetPredicate` must be a function');
  return pred;
}

function createAssetModel(options) {
  // required options
  var network = options.network,
      isNativeAsset = options.isNativeAsset,
      isDerivedAsset = options.isDerivedAsset;
  var _options$identity = options.identity,
      identity = _options$identity === void 0 ? function (asset) {
    return "".concat(network, "/").concat(asset.ident);
  } : _options$identity,
      _options$equalsFungib = options.equalsFungibleAsset,
      equalsFungibleAsset = _options$equalsFungib === void 0 ? function (assetA, assetB) {
    return assetA.network === network && assetB.network === network && (0, _lodash["default"])(assetA.ident, assetB.ident);
  } : _options$equalsFungib,
      _options$createFungib = options.createFungibleAsset,
      createFungibleAsset = _options$createFungib === void 0 ? function (assetLike) {
    return _objectSpread({
      amount: '0'
    }, assetLike);
  } : _options$createFungib,
      _options$createNative = options.createNativeAsset,
      createNativeAsset = _options$createNative === void 0 ? function (assetLike) {
    return _objectSpread({
      amount: '0'
    }, assetLike);
  } : _options$createNative,
      _options$isCurrentNet = options.isCurrentNetworkAsset,
      isCurrentNetworkAsset = _options$isCurrentNet === void 0 ? function (asset) {
    return asset.network === network && (isNativeAsset(asset) || isDerivedAsset(asset));
  } : _options$isCurrentNet;
  return {
    network: network,
    createNativeAsset: createNativeAsset,
    createFungibleAsset: createFungibleAsset,
    identity: identity,
    equalsFungibleAsset: equalsFungibleAsset,
    isCurrentNetworkAsset: createAssetPredicate(isCurrentNetworkAsset),
    isNativeAsset: createAssetPredicate(isNativeAsset),
    isDerivedAsset: createAssetPredicate(isDerivedAsset)
  };
}

var HEX_REGEX = /^0[xX][0-9a-fA-F]*$/;

function isValidHexStr(str) {
  if (typeof str !== 'string') return false;
  return HEX_REGEX.test(str);
}