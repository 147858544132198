"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasProp = hasProp;
exports.propEq = propEq;
exports.prop = void 0;

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function hasProp(obj, key) {
  return obj != null && _typeof(obj) === 'object' && key in obj;
}

var prop = function prop(obj, key, defaults) {
  if (hasProp(obj, key)) {
    return obj[key] || defaults;
  }

  return defaults;
};

exports.prop = prop;

function propEq(obj, key, v) {
  return prop(obj, key) === v;
}