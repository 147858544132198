"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unimplemented = unimplemented;
exports.boom = boom;

function unimplemented() {
  throw new Error('unimplemented method');
}

function boom(option) {
  if (typeof option === 'function') return option();
  if (typeof option === 'string') throw new Error(option);
  throw new Error('An unknown error has occurred');
}