"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NervosAssetModel = void 0;

var _constatns = require("../../constatns");

var _utils = require("../../utils");

var _helper = require("../helper");

var NervosAssetModel = (0, _helper.createAssetModel)({
  network: _constatns.NERVOS_NETWORK,
  isNativeAsset: function isNativeAsset(asset) {
    return (0, _utils.propEq)(asset, 'network', _constatns.NERVOS_NETWORK) && asset.ident == null;
  },
  isDerivedAsset: function isDerivedAsset(asset) {
    return (0, _utils.propEq)(asset, 'network', _constatns.NERVOS_NETWORK);
  }
});
exports.NervosAssetModel = NervosAssetModel;