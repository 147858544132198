"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EthereumModule = void 0;

var _helper = require("../helper");

var _AssetModel = require("./AssetModel");

var _constants = require("./constants");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var EthereumModule = {
  network: _constants.ETHEREUM_NETWORK,
  assetModel: _AssetModel.EthereumAssetModel,
  validators: {
    network: _constants.ETHEREUM_NETWORK,
    validateUserIdent: function validateUserIdent(userIdent) {
      return (0, _helper.isValidHexStr)(userIdent) && userIdent.length === 42;
    }
  },
  bridgeFeeModel: {
    network: _constants.ETHEREUM_NETWORK,
    calcBridgeFee: function calcBridgeFee(asset) {
      // TODO
      return _objectSpread(_objectSpread({}, asset), {}, {
        amount: '0'
      });
    }
  }
};
exports.EthereumModule = EthereumModule;