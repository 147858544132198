"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ERC20", {
  enumerable: true,
  get: function get() {
    return _ERC.ERC20;
  }
});
Object.defineProperty(exports, "Ether", {
  enumerable: true,
  get: function get() {
    return _Ether.Ether;
  }
});
Object.defineProperty(exports, "module", {
  enumerable: true,
  get: function get() {
    return _module.EthereumModule;
  }
});

var _ERC = require("./models/ERC20");

var _Ether = require("./models/Ether");

var _module = require("./module");