"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NervosModule = void 0;

var _ckbSdkUtils = require("@nervosnetwork/ckb-sdk-utils");

var _constatns = require("../../constatns");

var _AssetModel = require("./AssetModel");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var NervosModule = {
  network: _constatns.NERVOS_NETWORK,
  assetModel: _AssetModel.NervosAssetModel,
  bridgeFeeModel: {
    network: _constatns.NERVOS_NETWORK,
    calcBridgeFee: function calcBridgeFee(asset) {
      // TODO
      return _objectSpread(_objectSpread({}, asset), {}, {
        amount: '0'
      });
    }
  },
  validators: {
    network: _constatns.NERVOS_NETWORK,
    validateUserIdent: function validateUserIdent(address) {
      try {
        (0, _ckbSdkUtils.parseAddress)(address);
        return true;
      } catch (_unused) {
        return false;
      }
    }
  }
};
exports.NervosModule = NervosModule;